import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import config from '../../../config.json';
import {
  BackgroundTexture,
  DriftLogo,
  Layout,
  SEO,
  SideBySide,
  WinesMenu,
} from '../../components';
import { HeadingWithIcon } from '../../components/heading-with-icon';
import { useGraphQL } from '../../hooks';

function EatAndDrinkPage(): React.ReactElement {
  return (
    <Layout>
      <SEO title="Eat and Drink" />
      <SeeOurWines />
      <WinesMenu />
      {/* <Cocktails /> */}
      <DriftBarCafe />
    </Layout>
  );
}

function SeeOurWines(): React.ReactElement {
  const { eatAndDrinkWines } = useGraphQL();
  return (
    <div className="py-12">
      <SideBySide
        isReversed={false}
        sideComponent={
          <GatsbyImage
            image={eatAndDrinkWines.childImageSharp.gatsbyImageData}
            className="flex-1 h-full"
            alt=""
          />
        }
      >
        <div className="flex h-full py-8 bg-blue-light">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <h2 className="heading-2 heading-accent">
                See our
                <br />
                wines below
              </h2>
            </HeadingWithIcon>
            <div className="mt-2 prose">
              <p>
                We have personally selected an amazing range of wines from all
                around Australia, New Zealand and beyond. Try our selection
                today!
              </p>
            </div>
          </div>
        </div>
      </SideBySide>
    </div>
  );
}
function Cocktails(): React.ReactElement {
  const { cocktails } = useGraphQL();
  return (
    <div id="cocktails" className="relative py-12 scroll-margin-top-24">
      <SideBySide
        isReversed={false}
        sideComponent={
          <GatsbyImage
            image={cocktails.childImageSharp.gatsbyImageData}
            className="flex-1 h-full"
            alt=""
          />
        }
      >
        <div className="flex h-full py-8 bg-blue-light">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <HeadingWithIcon>
              <h2 className="heading-2 heading-accent">Cocktails</h2>
            </HeadingWithIcon>
            <div className="mt-2 prose">
              <p>
                All cocktails $15 Members | $17 Non-Members
                <br />
                Available only from the Main Bar
              </p>
            </div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="/cocktail-menu.pdf"
              className="max-w-sm mt-6 text-center button"
            >
              View cocktail menu
            </a>
          </div>
        </div>
      </SideBySide>
    </div>
  );
}

function DriftBarCafe(): React.ReactElement {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "drift-bar-cafe-restaurant.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);
  return (
    <div className="relative py-12">
      <BackgroundTexture overflowBottom insetBottom="full" />
      <div className="relative">
        <SideBySide
          sideComponent={
            <GatsbyImage
              image={file.childImageSharp.gatsbyImageData}
              className="flex-1 h-full"
              alt=""
            />
          }
        >
          <div className="w-full mx-auto max-w-prose">
            <HeadingWithIcon>
              <HeadingWithIcon.Heading>
                Drift
                <br />
                Bar.Cafe.
                <br />
                Restaurant
              </HeadingWithIcon.Heading>
              <HeadingWithIcon.Icon hidden>
                <DriftLogo />
              </HeadingWithIcon.Icon>
            </HeadingWithIcon>
            <div className="mt-2 prose">
              <p>
                Bringing you simple food done well, where every ingredient on
                the plate can be a hero and will make your taste buds sing!
                Please note, Drift specials are not guaranteed and while stocks
                last. Drift specials also are subject to change without prior
                notice.
              </p>
            </div>
          </div>
          <div className="grid w-full mx-auto mt-5 md:max-w-3xl gap-y-5 gap-x-4 md:grid-cols-2 lg:grid-cols-1">
            <Link
              to="/eat-and-drink/drift-bar-cafe-restaurant/"
              className="text-center button min-w-[14rem]"
            >
              See Drift bar.cafe.restaurant menu
            </Link>
            <a
              href={`tel:${config.phone}`}
              className="text-center bg-white border whitespace-nowrap button text-blue-dark border-blue-dark hover:text-white"
            >
              Call to book - {config.phone}
            </a>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

export { EatAndDrinkPage as default };
